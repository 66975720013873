import { useEffect, useMemo, useRef, useState } from 'react'

import { signIn, usePostMessageListener } from '@circlefin/auth'
import { Statement } from '@circlefin/components'
import { useOneTrust } from '@circlefin/one-trust'
import { homeSection } from '@services/sections/lib/home'
import { signupSection } from '@services/sections/lib/signup'
import classNames from 'classnames'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import type { NextConfig } from 'next/config'

const { publicRuntimeConfig } = getConfig() as NextConfig

export const AuthWebIFrame: React.FC = () => {
  const { t } = useTranslation('common')
  const iFrameRef = useRef<HTMLIFrameElement>(null)
  const router = useRouter()
  const oneTrust = useOneTrust()
  const circleAuthUrl = publicRuntimeConfig.CIRCLE_AUTH_URL

  const [signInParams, setSignInParams] = useState<string>()
  const [iframeLoading, setIFrameLoading] = useState(true)

  const oneTrustParams = useMemo(
    () =>
      `&oneTrustHasPerformance=${oneTrust.hasPerformance}&oneTrustHasTargeting=${oneTrust.hasTargeting}`,
    [oneTrust.hasPerformance, oneTrust.hasTargeting],
  )

  // Obtain NextAuth Params to render iFrame
  useEffect(() => {
    if (signInParams !== undefined) return

    const fetchParams = async () => {
      // if "from" url search param is given, set callbackUrl so user will be forwarded
      // to the correct page on sign in.
      const fromUrl = router.query.from

      const params = await signIn({
        redirect: false,
        callbackUrl:
          typeof fromUrl === 'string' && fromUrl ? fromUrl : homeSection.route,
      })

      setSignInParams(params)
    }

    void fetchParams()
  }, [router.query.from, signInParams])

  // Listen for iFrame child post messages
  usePostMessageListener(iFrameRef, {
    onAuthRedirect: (redirectUrl) => {
      window.location.replace(redirectUrl)
    },
    onSignUpClicked: () => {
      void router.push(signupSection.route)
    },
    onIsLoaded: () => {
      setIFrameLoading(false)
    },
  })

  return (
    <>
      {(signInParams === undefined || oneTrust.loading || iframeLoading) && (
        <div className="flex size-full items-center justify-center">
          <Statement
            iconName="Misc/Circle"
            title={t`sign-in-loading`}
            variant="page"
            loop
          />
        </div>
      )}

      {signInParams !== undefined && !oneTrust.loading && (
        <div
          className={classNames(
            'w-full h-full transition-opacity duration-300',
            {
              'opacity-0 h-0 fixed': iframeLoading,
              'opacity-100': !iframeLoading,
            },
          )}
        >
          <iframe
            ref={iFrameRef}
            className="size-full"
            src={`${circleAuthUrl}/mint?${signInParams}${oneTrustParams}`}
            title={t('sign-in')}
          />
        </div>
      )}
    </>
  )
}
