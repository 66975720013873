
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { SignIn } from '@features/auth/containers';
import { AuthLayout } from '@shared/layout';
import { AppPage } from '@shared/layout/core';
/**
 * Default No Session Page.
 */
const SignInPage = () => {
    return <SignIn />;
};
const __Next_Translate__Page__19171aa6e88__ = AppPage(SignInPage, {
    getLayout: (page) => <AuthLayout>{page}</AuthLayout>
});

    export default __appWithI18n(__Next_Translate__Page__19171aa6e88__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  