import { useEffect, useMemo } from 'react'

import { signIn } from '@circlefin/auth'
import { Statement } from '@circlefin/components'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { AuthWebIFrame } from '../AuthWebIFrame/AuthWebIFrame'

export const SignIn = () => {
  const { t } = useTranslation('common')
  const router = useRouter()

  const isSSOLogin = useMemo(() => {
    const from = router.query.from

    if (from == null || typeof from !== 'string') {
      return false
    }

    try {
      const fromUrl =
        from.startsWith('http://') || from.startsWith('https://')
          ? new URL(from)
          : new URL(from, window.location.origin)

      const fromLogin = new URLSearchParams(fromUrl.search).get('fromLogin')

      return fromLogin === 'true'
    } catch (err) {
      return false
    }
  }, [router.query])

  useEffect(() => {
    if (isSSOLogin) {
      // Redirect to okta hosted SignIn page
      void signIn({ legacy: true })
    }
  }, [isSSOLogin])

  if (!isSSOLogin) {
    return <AuthWebIFrame />
  }

  return (
    <div className="flex size-full items-center justify-center">
      <Statement
        iconName="Misc/Circle"
        title={t`sign-in-loading`}
        variant="page"
        loop
      />
    </div>
  )
}
